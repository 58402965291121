import React from 'react';
import { Link } from 'gatsby'
import {
  withPrismicUnpublishedPreview,
} from 'gatsby-plugin-prismic-previews'

import linkResolver from "../utils/linkResolver"
import Logo from '../components/Navigation/Logo'

const Page404 = () => (
  <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div className="max-w-sm w-full">
      <div className='flex justify-center'>
        <Link to='/'>
          <Logo className='w-40 mx-auto' />
        </Link>
      </div>

      <h1 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-800">
        Page Not Found
      </h1>
      <p className="mt-2 text-center text-sm leading-5 text-gray-600">
        <Link to='/dashboard' className='underline text-gray-500 hover:text-gray-600'>Go to Dashboard</Link>
      </p>
    </div>
  </div>
)

export default withPrismicUnpublishedPreview(
  Page404,
  [
    {
      repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
      linkResolver,
    },
  ],
)
